<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle>
        <v-chip
          v-if="formattedItem.estado_pendiente"
          small
          label
          dark
          color="red"
        >
          Pendiente
        </v-chip>
        <v-chip
          v-if="formattedItem.retrasado"
          small
          label
          dark
          color="red"
        >
          {{ formattedItem.fechaPrevistaServicio }}
        </v-chip>
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { toDate, currentDate } from '@/utils/date'
import { PEDIDO_CLIENTE } from '@/utils/consts'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.fechaPedidoCliente = this.$options.filters.shortDate(item.fpedidocli)
      if (item.fprevista_servicio) {
        item.fechaPrevistaServicio = this.$options.filters.shortDate(item.fprevista_servicio)
        item.retrasado = toDate(item.fprevista_servicio) <= currentDate()
      } else {
        item.fechaPrevistaServicio = ''
        item.retrasado = false
      }
      item.title = `${item.serie_numero} - ${item.cliente_nombre}`
      if (item.observaciones) {
        item.subtitle = `${item.fechaPedidoCliente} - ${item.observaciones}`
      } else {
        item.subtitle = item.fechaPedidoCliente
      }
      if (item.estado === PEDIDO_CLIENTE.estados.pendiente) {
        item.avatar = PEDIDO_CLIENTE.alias.pendiente
        item.avatarColor = PEDIDO_CLIENTE.colores.pendiente
      } else if (item.estado === PEDIDO_CLIENTE.estados.sirviendo) {
        item.avatar = PEDIDO_CLIENTE.alias.sirviendo
        item.avatarColor = PEDIDO_CLIENTE.colores.sirviendo
      } else if (item.estado === PEDIDO_CLIENTE.estados.servido) {
        item.avatar = PEDIDO_CLIENTE.alias.servido
        item.avatarColor = PEDIDO_CLIENTE.colores.servido
      } else if (item.estado === PEDIDO_CLIENTE.estados.finalizado) {
        item.avatar = PEDIDO_CLIENTE.alias.finalizado
        item.avatarColor = PEDIDO_CLIENTE.colores.finalizado
      }
      item.puede_servir = (
        item.estado === PEDIDO_CLIENTE.estados.pendiente ||
        item.estado === PEDIDO_CLIENTE.estados.sirviendo
      )
      return item
    }
  }
}
</script>
